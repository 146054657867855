import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getDistributionPanelTypes } from '../../actions/app';
import { ApplicationState } from '../../reducers';
import { DistributionPanelType } from '../../reducers/app';

interface OwnProps {
  onSelect: (distributionPanelId: string) => void;
  value?: string;
  error: boolean;
  disabled?: boolean;
}

interface Props extends OwnProps {
  getDistributionPanelTypes: typeof getDistributionPanelTypes;
  distributionPanelTypes?: DistributionPanelType[];
}

interface State {
  search: string;
}

class SelectDistributionPanelType extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { distributionPanelTypes, getDistributionPanelTypes } = this.props;
    if (!distributionPanelTypes) getDistributionPanelTypes();
  }

  render() {
    const {
      distributionPanelTypes,
      value,
      onSelect,
      error,
      disabled,
    } = this.props;

    if (!distributionPanelTypes) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;

      const listedDistributionPanelTypes = (distributionPanelTypes || [])
        //TODO: remove this filter once we are able to visualize panelType
        .filter((panelType: DistributionPanelType) => {
          return (
            // Might need to recomment this out once proper US type is added
            // panelType.id !== 'EU_HORIZONTAL_SINGLE_PHASE' &&
            //THERE_PHASE is a typo from BE return
            panelType.id !== 'EU_HORIZONTAL_THERE_PHASE' &&
            panelType.id !== 'EU_HORIZONTAL_SINGLE_PHASE'
          );
        })
        .filter((panelType: DistributionPanelType) => {
          if (search.length <= 2) return true;
          return hasSubstr(panelType.title, search);
        });

      const selectedDistributionPanelType = distributionPanelTypes.find(
        eq => eq.id === value
      );

      return (
        <Select
          disabled={disabled}
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedDistributionPanelType?.title}
          value={selectedDistributionPanelType?.id}
          error={error}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
          />
          {listedDistributionPanelTypes.map((eq: DistributionPanelType) => (
            <SelectItem
              key={eq.id}
              isSelected={eq.id === selectedDistributionPanelType?.id}
              onSelect={() => onSelect(eq.id)}
            >
              {eq.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  distributionPanelTypes: app.distributionPanelTypes,
});

const mapDispatchToProps = {
  getDistributionPanelTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectDistributionPanelType);
