import {
  FlattenedAstroArray,
  LightReading,
  LightSensorPort,
  SiteAstronomicalClock,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  global,
  isDefined,
  renderAstroEventTime,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import { differenceInSeconds, isAfter, isBefore, parseISO } from 'date-fns';
import { DateTime } from 'luxon';
import { DeviceState, DeviceStatusById } from '../reducers/deviceStatus';
import { SensorReading, SensorReadingFromAPI } from '../reducers/sensors';

export const IGNORE_DEFAULT_TIMESTAMPS = [
  '1970-01-01T00:00:00Z',
  '1970-01-01T00:00:00.000Z',
  '1970-01-01T00:00:00',
];

export const normalizeSubscribedSensorReading = (
  rawData: SensorReadingFromAPI,
  lightSensorPort?: LightSensorPort
) => ({
  processedAt: rawData.processedAt,
  receivedAt: rawData.receivedAt,
  sensorId: rawData.sensorId,
  sensor: rawData.sensor,
  timestamp: rawData.timestamp,
  uuid: rawData.uuid,
  vendor: rawData.vendor,
  temperature: rawData.temperature,
  humidity: rawData.humidity,
  lux: lightSensorPort === LightSensorPort.PORT_1 ? rawData.port1Lux : rawData.port2Lux,
  lux1Range: rawData.lux1Range,
  lux2Range: rawData.lux2Range,
  binary:
    rawData.state && Array.isArray(rawData.state)
      ? rawData.state[0]
      : undefined,
});

export const checkIfReadingIsMoreRecent = (
  newReading: SensorReading,
  oldReading?: SensorReading
) => {
  if (!oldReading) return true;
  return isAfter(
    new Date(newReading.timestamp),
    new Date(oldReading.timestamp)
  );
};

export const calcFlatAstroArrayWithLux = (
  astroClockArray: SiteAstronomicalClock[],
  luxData: LightReading[],
  timezone: string
) => {
  const flatAstroArrayWithLux: FlattenedAstroArray[] = [];
  // Need to transform astroClockArray into a more flattened array - insight is
  // that the events will come strictly in chronological order after flattening,
  // so we can then process them one at a time
  const flatAstroArray: FlattenedAstroArray[] = astroClockArray.reduce<
    FlattenedAstroArray[]
  >((acc, curr) => {
    const newAcc: FlattenedAstroArray[] = [...acc];
    newAcc.push({ type: 'dawn', time: curr.dawn, date: curr.date });
    newAcc.push({ type: 'sunrise', time: curr.sunrise, date: curr.date });
    newAcc.push({ type: 'sunset', time: curr.sunset, date: curr.date });
    newAcc.push({ type: 'dusk', time: curr.dusk, date: curr.date });
    return newAcc;
  }, []);

  let lastLuxValue = 0;
  let lastDate = new Date();

  luxData.forEach(({ timestamp, lux }) => {
    // Iterate over each data lux datas point. At each point we examine if the
    // current astro event has happened yet, if not keep going. Once we pass the
    // current astro event we can determine if the data point right before or
    // after the astro event is closer. Alternatively, those two lux values
    // could be average to smooth the data slightly (but possibly irrelevant due
    // to differences in lux values often being less than 1 and the average
    // being rounded)
    if (flatAstroArray.length === 0 || !isDefined(lux)) return;
    const astroEvent = flatAstroArray[0];

    const newAtro = DateTime.fromISO(
      `${astroEvent.date}T${astroEvent.time.trim()}`
    ).toISO();
    const newDataPoint = DateTime.fromMillis(timestamp).setZone(timezone);

    const astroEventDate = DateTime.fromISO(newAtro).toJSDate();
    const dataPointDate = new Date(
      newDataPoint.year,
      newDataPoint.month - 1, // Months in JavaScript are zero-indexed
      newDataPoint.day,
      newDataPoint.hour,
      newDataPoint.minute,
      newDataPoint.second,
      newDataPoint.millisecond
    );

    if (isBefore(dataPointDate, astroEventDate)) {
      lastLuxValue = lux;
      lastDate = dataPointDate;
    } else if (isAfter(dataPointDate, astroEventDate)) {
      if (
        Math.abs(differenceInSeconds(lastDate, astroEventDate)) <
        Math.abs(differenceInSeconds(astroEventDate, dataPointDate))
      ) {
        // lastDate was closer
        astroEvent.luxValue = lastLuxValue;
      } else {
        // dataPointDate is closer
        astroEvent.luxValue = lux;
      }
      const astroEventWithLux = flatAstroArray.shift();
      if (isDefined(astroEventWithLux)) {
        flatAstroArrayWithLux.push(astroEventWithLux);
      }
    }
  });

  return flatAstroArrayWithLux;
};

export const calculateAstroEventLuxAveragesFromFlatArray = (
  astroClockArray: SiteAstronomicalClock[],
  flatAstroArrayWithLux: FlattenedAstroArray[]
) => {
  const totalLuxValuesByAstroEvent = flatAstroArrayWithLux.reduce(
    (avgDict, { type, luxValue }) => {
      const newAvgDict = { ...avgDict };
      newAvgDict[type] += luxValue;
      return newAvgDict;
    },
    { dawn: 0, sunrise: 0, sunset: 0, dusk: 0 }
  );

  const numDays = astroClockArray.length;
  Object.keys(totalLuxValuesByAstroEvent).forEach(key => {
    const avg = Math.round(totalLuxValuesByAstroEvent[key] / numDays);
    totalLuxValuesByAstroEvent[key] = avg;
  });

  // Just pick the middle for the average time. Much easier than trying to parse
  // the strings and do the math. Rather, just rely on the fact that the times
  // are moving in the same direction (i.e. days getting longer or shorter) and picking the middle will be close
  // enough
  const midIdx = Math.floor(astroClockArray.length / 2);

  return {
    dawn: {
      lux: totalLuxValuesByAstroEvent.dawn,
      time: renderAstroEventTime(astroClockArray, midIdx, 'dawn'),
      timeWithSeconds: astroClockArray[midIdx].dawn,
    },
    sunrise: {
      lux: totalLuxValuesByAstroEvent.sunrise,
      time: renderAstroEventTime(astroClockArray, midIdx, 'sunrise'),
      timeWithSeconds: astroClockArray[midIdx].sunrise,
    },
    sunset: {
      lux: totalLuxValuesByAstroEvent.sunset,
      time: renderAstroEventTime(astroClockArray, midIdx, 'sunset'),
      timeWithSeconds: astroClockArray[midIdx].sunset,
    },
    dusk: {
      lux: totalLuxValuesByAstroEvent.dusk,
      time: renderAstroEventTime(astroClockArray, midIdx, 'dusk'),
      timeWithSeconds: astroClockArray[midIdx].dusk,
    },
  };
};

export const doTimestampsContainDefaults = onlineStatesUpdatedAt => {
  let flag = false;

  onlineStatesUpdatedAt.forEach(({ timestamp, onlineStateUpdatedAt }) => {
    if (
      IGNORE_DEFAULT_TIMESTAMPS.includes(timestamp) ||
      (isDefined(onlineStateUpdatedAt) &&
        IGNORE_DEFAULT_TIMESTAMPS.includes(onlineStateUpdatedAt))
    ) {
      flag = true;
    }
  });

  return flag;
};

export const getDeviceStatusSortingFn = (
  deviceStatusById: DeviceStatusById,
  isNetworkGroup?: boolean
) => (a, b, sortDirection: SortDirection) => {
  const deviceStateA: DeviceState | undefined = isNetworkGroup
    ? deviceStatusById[a?.edge?.uuid]
    : deviceStatusById[a?.id];
  const deviceStateB: DeviceState | undefined = isNetworkGroup
    ? deviceStatusById[b?.edge?.uuid]
    : deviceStatusById[b?.id];
  const onlineStateA = deviceStateA?.onlineState;
  const onlineStateB = deviceStateB?.onlineState;

  const isTimestampAInvalid: boolean = doTimestampsContainDefaults([
    {
      timestamp: deviceStateA?.timestamp,
      onlineStateUpdatedAt: deviceStateA?.onlineStateUpdatedAt,
    },
  ]);
  const isTimestampBInvalid: boolean = doTimestampsContainDefaults([
    {
      timestamp: deviceStateB?.timestamp,
      onlineStateUpdatedAt: deviceStateB?.onlineStateUpdatedAt,
    },
  ]);

  if (isDefined(onlineStateA)) {
    a['onlineState'] = onlineStateA ? 0 : 1;
  } else {
    a['onlineState'] = global.NOT_AVAILABLE;
  }

  if (isDefined(onlineStateB)) {
    b['onlineState'] = onlineStateB ? 0 : 1;
  } else {
    b['onlineState'] = global.NOT_AVAILABLE;
  }

  if (isTimestampAInvalid) a['onlineState'] = global.NOT_AVAILABLE;
  if (isTimestampBInvalid) b['onlineState'] = global.NOT_AVAILABLE;
  return genericTableSort(
    a,
    b,
    sortDirection,
    SORT_IGNORED_VALUES,
    ['onlineState'],
    item => {
      const timestamp = getTimeStamp(item, isNetworkGroup, deviceStatusById);
      return -timestamp;
    },
    ['title']
  );
};

export const getTimeStamp = (item, isNetworkGroup, deviceStatusById) => {
  if (isNetworkGroup) {
    return parseISO(
      deviceStatusById[item?.edge?.uuid]
        ? selectTimestampToUse(deviceStatusById[item?.edge?.uuid]) || ''
        : ''
    )?.getTime();
  } else {
    return parseISO(
      deviceStatusById[item?.id]
        ? selectTimestampToUse(deviceStatusById[item?.id]) || ''
        : ''
    )?.getTime();
  }
};

export const selectTimestampToUse = ({
  timestamp,
  onlineStateUpdatedAt,
  onlineState,
}: {
  timestamp: string;
  onlineStateUpdatedAt?: string;
  onlineState?: boolean;
}) => {
  return onlineState === false ? onlineStateUpdatedAt || timestamp : timestamp;
};

export const pickOldestTimestamp = (
  timestamps: (string | null | undefined)[]
): string | null => {
  const filteredTimestamps = timestamps.filter(t => isDefined(t)) as string[];

  if (filteredTimestamps.length === 0) {
    return null;
  }

  if (filteredTimestamps.length === 1) {
    return filteredTimestamps[0];
  }

  const earliest = filteredTimestamps.reduce(function(pre, cur) {
    return parseISO(pre) > parseISO(cur) ? cur : pre;
  });

  return earliest;
};

export const formatUuid = (uuid: string, checkValid = false) => {
  return (
    uuid
      .replace(/[^0-9A-Fa-f]/g, '')
      ?.slice(0, 12)
      ?.toUpperCase()
      ?.match(/.{1,2}/g)
      ?.join(':') || (checkValid ? global.NOT_AVAILABLE : '')
  );
};
