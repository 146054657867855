import { LightReadingDataStatus, LightSensorPort } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';
import { DateTime } from 'luxon';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLightReadingsByControlBoardId,
  getSchedulerReadingsBySchedulerId,
} from '../../actions/controls';
import { ApplicationState } from '../../reducers';

/**
 * @param fromDate ISO 8601 string
 * @param toDate ISO 8601 string
 */
export const useLightReadingByControlBoardId = (
  controlBoardId: number | string | undefined | null,
  fromDate: string,
  toDate: string,
  lightSensorPort?: LightSensorPort
) => {
  const dispatch = useDispatch();

  const lightReadingDataStatus:
    | LightReadingDataStatus
    | undefined = useSelector(({ controls }: ApplicationState) => {
    if (!isDefined(controlBoardId)) return undefined;
    return pathOr(
      undefined,
      ['timeSeries', 'lightSensorReadings', 'byControlBoardId', controlBoardId],
      controls
    );
  });

  useEffect(() => {
    if (!controlBoardId) return;
    dispatch(
      getLightReadingsByControlBoardId(
        controlBoardId,
        DateTime.fromISO(fromDate).toMillis(),
        DateTime.fromISO(toDate).toMillis(),
        lightSensorPort
      )
    );
  }, [controlBoardId, fromDate, toDate, dispatch]);

  return lightReadingDataStatus;
};

export const useLightReadingBySchedulerControlId = (
  schedulerId: number | string | undefined | null,
  fromDate: number,
  toDate: number
) => {
  const dispatch = useDispatch();

  const lightReadingDataStatus:
    | LightReadingDataStatus
    | undefined = useSelector(({ controls }: ApplicationState) => {
    if (!isDefined(schedulerId)) return undefined;
    return pathOr(
      undefined,
      ['timeSeries', 'lightSensorReadings', 'bySchedulerId', schedulerId],
      controls
    );
  });

  useEffect(() => {
    if (!isDefined(schedulerId)) return;
    dispatch(
      getSchedulerReadingsBySchedulerId(
        schedulerId,
        fromDate.valueOf(),
        toDate.valueOf()
      )
    );
  }, [schedulerId, fromDate, toDate, dispatch]);

  return lightReadingDataStatus;
};
