import {
  ControlsType,
  LightSensorPort,
  SiteAstronomicalClock,
  TimeFilterMappingArguments,
} from '@energybox/react-ui-library/dist/types';
import {
  formatDateForTimeSeriesApi,
  createTimeFilterMapping,
} from '@energybox/react-ui-library/dist/utils';
import { EnergyboxService } from '../config';

export enum Actions {
  TOGGLE_NEW_CONTROL_MODAL = '@controls/TOGGLE_NEW_CONTROL_MODAL',
  TOGGLE_EDIT_CONTROL_MODAL = '@controls/TOGGLE_EDIT_CONTROL_MODAL',
  TOGGLE_DELETE_CONTROL_MODAL = '@controls/TOGGLE_DELETE_CONTROL_MODAL',
  TOGGLE_UPDATE_CONTROL_MODE_MODAL = '@controls/TOGGLE_UPDATE_CONTROL_MODE_MODAL',
  CLEAR_UPDATE_CONTROL_MODE_MODAL_ERROR_MESSAGE = '@controls/CLEAR_UPDATE_CONTROL_MODE_MODAL_ERROR_MESSAGE',

  GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_SUCCESS = '@controls/GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_SUCCESS',
  GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_ERROR = '@controls/GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_ERROR',
  GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_LOADING = '@controls/GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_LOADING',

  GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS = '@controls/GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS',
  GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_ERROR = '@controls/GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_ERROR',
  GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_LOADING = '@controls/GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_LOADING',

  GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_SUCCESS = '@controls/GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_SUCCESS',
  GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_ERROR = '@controls/GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_ERROR',
  GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_LOADING = '@controls/GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_LOADING',

  GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_SUCCESS = '@controls/GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_SUCCESS',
  GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_ERROR = '@controls/GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_ERROR',
  GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_LOADING = '@controls/GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_LOADING',

  GET_ACTUATOR_CIRCUIT_ACTIVITY_SUCCESS = '@controls/GET_ACTUATOR_CIRCUIT_ACTIVITY_SUCCESS',
  GET_ACTUATOR_CIRCUIT_ACTIVITY_ERROR = '@controls/GET_ACTUATOR_CIRCUIT_ACTIVITY_ERROR',
  GET_ACTUATOR_CIRCUIT_ACTIVITY_LOADING = '@controls/GET_ACTUATOR_CIRCUIT_ACTIVITY_LOADING',

  GET_LOCAL_OVERRIDE_ACTIVITY_SUCCESS = '@controls/GET_LOCAL_OVERRIDE_ACTIVITY_SUCCESS',
  GET_LOCAL_OVERRIDE_ACTIVITY_ERROR = '@controls/GET_LOCAL_OVERRIDE_ACTIVITY_ERROR',
  GET_LOCAL_OVERRIDE_ACTIVITY_LOADING = '@controls/GET_LOCAL_OVERRIDE_ACTIVITY_LOADING',

  GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_SUCCESS = '@controls/GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_SUCCESS',
  GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_ERROR = '@controls/GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_ERROR',
  GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_LOADING = '@controls/GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_LOADING',
}

export type PostSchedulerParams = {
  beginDelta: number;
  endDelta: number;
  equipmentId: number;
  timetableId: number;
};

export const showNewControlModal = () => ({
  type: Actions.TOGGLE_NEW_CONTROL_MODAL,
  value: true,
});

export const hideNewControlModal = () => ({
  type: Actions.TOGGLE_NEW_CONTROL_MODAL,
  value: false,
});

export const showEditControlModal = (controlType: ControlsType) => ({
  type: Actions.TOGGLE_EDIT_CONTROL_MODAL,
  value: true,
  payload: controlType,
});

export const hideEditControlModal = () => ({
  type: Actions.TOGGLE_EDIT_CONTROL_MODAL,
  value: false,
});

export const showDeleteControlModal = (controlType: ControlsType) => ({
  type: Actions.TOGGLE_DELETE_CONTROL_MODAL,
  value: true,
});

export const hideDeleteControlModal = () => ({
  type: Actions.TOGGLE_DELETE_CONTROL_MODAL,
  value: false,
});

export const showUpdateControlModeModal = () => ({
  type: Actions.TOGGLE_UPDATE_CONTROL_MODE_MODAL,
  value: true,
});

export const hideUpdateControlModeModal = () => ({
  type: Actions.TOGGLE_UPDATE_CONTROL_MODE_MODAL,
  value: false,
});

export const clearUpdateControlModeModalErrorMessage = () => ({
  type: Actions.CLEAR_UPDATE_CONTROL_MODE_MODAL_ERROR_MESSAGE,
});

export const getLightReadingsByControlBoardId = (
  controlBoardId: string | number,
  fromDate: number,
  toDate: number,
  lightSensorPort?: LightSensorPort
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: EnergyboxService.timeSeries,
    path: `/light/sensor/${controlBoardId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_SUCCESS,
      controlBoardId,
      lightSensorPort,
    },
    error: {
      type: Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_ERROR,
      controlBoardId,
      lightSensorPort,
    },
    loading: {
      type: Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_LOADING,
      controlBoardId,
      lightSensorPort,
    },
  };
};

export const getSchedulerReadingsBySchedulerId = (
  schedulerId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: EnergyboxService.timeSeries,
    path: `/scheduler-control/status/${schedulerId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS,
      schedulerId,
    },
    error: {
      type: Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS,
      schedulerId,
    },
    loading: {
      type: Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_LOADING,
      schedulerId,
    },
  };
};

export const getLightReadings7DayAvgByControlBoardId = (
  controlBoardId: string | number,
  astroClockArray: SiteAstronomicalClock[],
  timezone: string
) => {
  const timeFilterArguments: TimeFilterMappingArguments = {
    useCurrentTime: true,
    ianaTimeZoneCode: timezone,
  };
  const timeFiltersMapping = createTimeFilterMapping(timeFilterArguments);

  const endDate = timeFiltersMapping.last7Days.toDate;
  const startDate = timeFiltersMapping.last7Days.fromDate;
  const formattedFromDate = formatDateForTimeSeriesApi(startDate);
  const formattedToDate = formatDateForTimeSeriesApi(endDate);

  return {
    type: 'API_GET',
    service: EnergyboxService.timeSeries,
    path: `/light/sensor/${controlBoardId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_SUCCESS,
      controlBoardId,
      astroClockArray,
      timezone,
    },
    error: {
      type: Actions.GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_ERROR,
      controlBoardId,
      astroClockArray,
      timezone,
    },
    loading: {
      type: Actions.GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_LOADING,
      controlBoardId,
      astroClockArray,
      timezone,
    },
  };
};

export const getLightReadingsYesterdayByControlBoardId = (
  controlBoardId: string | number,
  astroClockArray: SiteAstronomicalClock[],
  timezone: string
) => {
  const timeFilterArguments: TimeFilterMappingArguments = {
    useCurrentTime: true,
    ianaTimeZoneCode: timezone,
  };
  const timeFiltersMapping = createTimeFilterMapping(timeFilterArguments);

  const endDate = timeFiltersMapping.yesterday.toDate;
  const startDate = timeFiltersMapping.yesterday.fromDate;
  const formattedFromDate = formatDateForTimeSeriesApi(startDate);
  const formattedToDate = formatDateForTimeSeriesApi(endDate);

  return {
    type: 'API_GET',
    service: EnergyboxService.timeSeries,
    path: `/light/sensor/${controlBoardId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_SUCCESS,
      controlBoardId,
      astroClockArray,
      timezone,
    },
    error: {
      type: Actions.GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_ERROR,
      controlBoardId,
      astroClockArray,
      timezone,
    },
    loading: {
      type: Actions.GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_LOADING,
      controlBoardId,
      astroClockArray,
      timezone,
    },
  };
};

export const getActuatorCircuitActivityById = (
  actuatorId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: EnergyboxService.timeSeries,
    path: `/actuators/${actuatorId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_SUCCESS,
      actuatorId,
    },
    error: { type: Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_ERROR, actuatorId },
    loading: {
      type: Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_LOADING,
      actuatorId,
    },
  };
};

export const getLocalOverrideActivityByControlBoardId = (
  controlBoardId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));
  const timeRange = `${fromDate}-${toDate}`;

  return {
    type: 'API_GET',
    service: EnergyboxService.timeSeries,
    path: `/gateways/status/cb-overridden-state/${controlBoardId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_LOCAL_OVERRIDE_ACTIVITY_SUCCESS,
      controlBoardId,
      timeRange,
    },
    error: {
      type: Actions.GET_LOCAL_OVERRIDE_ACTIVITY_ERROR,
      controlBoardId,
      timeRange,
    },
    loading: {
      type: Actions.GET_LOCAL_OVERRIDE_ACTIVITY_LOADING,
      controlBoardId,
      timeRange,
    },
  };
};

export const getSystemOverrideActivityByControlId = (
  controlId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: EnergyboxService.timeSeries,
    path: `/control-agent-module/status/${controlId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_SUCCESS,
      controlId,
    },
    error: {
      type: Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_ERROR,
      controlId,
    },
    loading: {
      type: Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_LOADING,
      controlId,
    },
  };
};
