import {
  Card,
  CardContent,
  CardTitle,
  DateFilter,
  LightSensorChart,
  Table,
  TimeDistance,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import {
  LightReading,
  LightReadingHistoricDatapoint,
  IsoDateFormat,
  LightSensorPort,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  isDefined,
  genericDateFormat,
} from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';

import React, { useMemo } from 'react';
import { MdLens } from 'react-icons/md';
import { useLightReadingByControlBoardId } from '../../../hooks/controls/scheduler';
import { useAppLocale } from '../../../hooks/useAppDetails';
import useChartDetails, {
  ChartDetailOptions,
} from '../../../hooks/useChartDetails';
import useSiteAstroClock, {
  useCalculateLast7DayAvgAstronomicalLuxValues,
  useCalculateYesterdayAstronomicalLuxValues,
} from '../../../hooks/useSiteAstroClock';
import { SensorReading } from '../../../reducers/sensors';
import { formatDecimalValue } from '../../../utils/numbers';
import gatewayCardStyle from '../GatewayCommonCardStyle.module.css';
import styles from './LightSensorChartContainer.module.css';

type Props = {
  siteId: number | string;
  controlBoardId: number | string;
  isControlBoardOnline: boolean;
  timezone: string;
  liveLuxReading?: SensorReading;
  lightSensorPort?: LightSensorPort;
};

type AstronomicalDataRowEntry = {
  rowName: string;
  time?: string;
  avg7DaysData: LightReadingHistoricDatapoint;
  yesterdayData: LightReadingHistoricDatapoint;
};

const LightSensorChartContainer = ({
  siteId,
  controlBoardId,
  timezone,
  isControlBoardOnline,
  liveLuxReading,
  lightSensorPort
}: Props) => {
  const locale = useAppLocale();
  const historicData = useHistoricValues(siteId, timezone, controlBoardId);

  const chartDetailOptions: ChartDetailOptions = useMemo(() => {
    return {
      useCurrentTime: true,
      timezone,
    };
  }, [timezone]);

  const {
    fromDate,
    toDate,
    refAreaStart,
    setRefAreaStart,
    refAreaEnd,
    setRefAreaEnd,
    zoomIn,
    dateFilterFromDate,
    dateFilterToDate,
    dateFilterTitle,
    setDateFilter,
    zoomOut,
    isZoomedIn,
    isMonthly,
    isCustomRange,
  } = useChartDetails(locale, chartDetailOptions);

  const lightReadingTimeSeriesStatus = useLightReadingByControlBoardId(
    controlBoardId,
    fromDate,
    toDate,
    lightSensorPort
  );

  const lightReadingTimeSeries: LightReading[] | undefined =
    lightReadingTimeSeriesStatus?.data;

  const minLux = lightReadingTimeSeriesStatus?.min || 0;
  const maxLux = lightReadingTimeSeriesStatus?.max || 100;

  return (
    <Card>
      <CardContent className={gatewayCardStyle.cardTitleExtraPadding}>
        <CardTitle>Light Sensor Reading</CardTitle>
        <div className={styles.liveLuxReadingContainer}>
          <MdLens
            size={16}
            color={
              isControlBoardOnline ? 'var(--green-base)' : 'var(--pink-base)'
            }
          />{' '}
          <div className={styles.label}>Live Sensor Reading:</div>
          {isDefined(liveLuxReading?.lux) ? (
            <>
              <div className={styles.liveValue}>{`${formatDecimalValue(
                liveLuxReading?.lux,
                0,
                'lux'
              )}`}</div>
              <div>
                <TimeDistance timestamp={liveLuxReading?.timestamp} />
              </div>
            </>
          ) : (
            <div className={styles.liveValue}>{global.NOT_AVAILABLE}</div>
          )}
        </div>
        <div className={styles.lightChartFilter}>
          <DateFilter
            alignItemsRight
            setFilter={setDateFilter}
            value={{
              title: dateFilterTitle,
              toDate: DateTime.fromISO(dateFilterToDate).toJSDate(),
              fromDate: DateTime.fromISO(dateFilterFromDate).toJSDate(),
              isMonthly,
              isCustomRange,
            }}
            dateFormat="datetime"
            customPickerVariant="datetime"
            ianaTimeZoneCode={timezone}
            displayDatesAsTitle
            locale={locale}
          />
          <div className={styles.zoomResetContainer}>
            <ResetButton
              customText="Reset Zoom"
              displayInfoTooltip
              onClick={zoomOut}
              disabled={!isZoomedIn}
            />
          </div>
        </div>

        <LightSensorChart
          data={lightReadingTimeSeries || []}
          refAreaStart={refAreaStart}
          refAreaEnd={refAreaEnd}
          updateRefAreaStart={setRefAreaStart}
          updateRefAreaEnd={setRefAreaEnd}
          fromDate={DateTime.fromISO(fromDate).toMillis()}
          toDate={DateTime.fromISO(toDate).toMillis()}
          zoomIn={zoomIn}
          locale={locale}
          ianaTimeZoneCode={timezone}
          displayInSiteTimezone
          hideThreshold
        />
        <div>
          <Table
            data={historicData || []}
            columns={historicDataTableColumns}
            highlightAlternateRows
          />
        </div>
      </CardContent>
    </Card>
  );
};

const historicDataTableColumns: Columns<AstronomicalDataRowEntry>[] = [
  {
    header: '',
    cellContent: row => row.rowName,
  },
  {
    header: <div className={styles.historicHeader}>Today</div>,
    width: '15%',
    cellContent: row => row.time?.slice(0, -4),
  },
  {
    header: <div className={styles.historicHeader}>Yesterday</div>,
    width: '15%',
    cellContent: row => row.yesterdayData.time,
  },
  {
    header: <div className={styles.historicHeader}>Brightness</div>,
    width: '13%',
    cellContent: row =>
      isDefined(row.yesterdayData.lux)
        ? `${row.yesterdayData.lux} lux`
        : global.NOT_AVAILABLE,
  },
  {
    header: <div className={styles.historicHeader}>7 Day Average</div>,
    width: '15%',
    cellContent: row => row.avg7DaysData.time,
  },
  {
    header: <div className={styles.historicHeader}>Average Brightness</div>,
    width: '13%',
    cellContent: row =>
      isDefined(row.avg7DaysData.lux)
        ? `${row.avg7DaysData.lux} lux`
        : global.NOT_AVAILABLE,
  },
];

const useHistoricValues = (
  siteId: string | number,
  timezone: string,
  controlBoardId: string | number
): AstronomicalDataRowEntry[] | undefined => {
  const todayDateString = genericDateFormat(
    new Date(),
    IsoDateFormat,
    timezone
  );

  const todayAstroTime = useSiteAstroClock(siteId, todayDateString);
  const {
    lightReadingsLast7DaysAvg: avg7DaysData,
  } = useCalculateLast7DayAvgAstronomicalLuxValues(
    siteId,
    controlBoardId,
    timezone
  );

  const {
    lightReadingsYesterday: yesterdayData,
  } = useCalculateYesterdayAstronomicalLuxValues(
    siteId,
    controlBoardId,
    timezone
  );
  if (
    !todayAstroTime ||
    todayAstroTime?.length === 0 ||
    !isDefined(avg7DaysData) ||
    !isDefined(yesterdayData)
  )
    return undefined;

  return [
    {
      rowName: 'Dawn',
      time: todayAstroTime[0].dawn,
      avg7DaysData: avg7DaysData.dawn,
      yesterdayData: yesterdayData.dawn,
    },
    {
      rowName: 'Sunrise',
      time: todayAstroTime[0].sunrise,
      avg7DaysData: avg7DaysData.sunrise,
      yesterdayData: yesterdayData.sunrise,
    },

    {
      rowName: 'Sunset',
      time: todayAstroTime[0].sunset,
      avg7DaysData: avg7DaysData.sunset,
      yesterdayData: yesterdayData.sunset,
    },
    {
      rowName: 'Dusk',
      time: todayAstroTime[0].dusk,
      avg7DaysData: avg7DaysData.dusk,
      yesterdayData: yesterdayData.dusk,
    },
  ];
};

export default LightSensorChartContainer;
