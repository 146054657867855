import {
  ActuatorActivityApiReturn,
  ActuatorCircuitActivityById,
  ControlsType,
  LightReading,
  LightReadingDataStatus,
  LightReadingsHistoricData,
  LightSensorPort,
  LocalOverrideActivityApiReturn,
  LocalOverrideActivityByControlBoardIdAndTimeRange,
  SiteAstronomicalClock,
  SystemOverrideActivity,
  SystemOverrideActivityApiReturn,
  SystemOverrideActivityDataStatus,
  SystemOverrideApiRes,
  WorkingModeMap,
  ZippedActuatorCircuitActivityData,
  ZippedLocalOverrideActivityData,
  ZippedTimeSeriesApiRes,
} from '@energybox/react-ui-library/dist/types';
import { parseISO } from 'date-fns';
import { assocPath, pipe } from 'ramda';
import { Actions } from '../actions/controls';
import { getUnixTimestamp } from '../utils/dates';
import {
  calcFlatAstroArrayWithLux,
  calculateAstroEventLuxAveragesFromFlatArray,
} from '../utils/devices';
import { getTimeSeriesMinMax, zipTSApiData } from '../utils/timeSeries';

export type LightReadingByControlBoardId = {
  [controlBoardId: number]: LightReadingDataStatus;
};
export type LightReadingBySchedulerId = {
  [schedulerId: number]: LightReadingDataStatus;
};
export type LightReadingsHistoricDataset = {
  avg7Days?: LightReadingsHistoricData | null;
  yesterday?: LightReadingsHistoricData | null;
  avg7DaysIsLoading: boolean;
  yesterdayIsLoading: boolean;
};

export type Controls = {
  showNewControlModal: boolean;
  showEditControlModal: boolean;
  showDeleteControlModal: boolean;
  showUpdateControlModeModal: boolean;
  typeOfControl?: ControlsType;
  timeSeries: ControlsTimeSeries;
  historicDataByControlBoardId: {
    lightReadings: {
      [controlBoardId: number]: LightReadingsHistoricDataset;
    };
  };
  actuatorCircuitActivityById: ActuatorCircuitActivityById;
  localOverrideActivityByControlBoardId: LocalOverrideActivityByControlBoardIdAndTimeRange;
  systemOverrideByControlId: SystemOverrideByControlId;
};

export type ControlsTimeSeries = {
  lightSensorReadings: {
    byControlBoardId: LightReadingByControlBoardId;
    bySchedulerId: LightReadingBySchedulerId;
  };
};

export type SystemOverrideByControlId = {
  [controlId: string]: SystemOverrideActivityDataStatus | undefined;
};

const initialState: Controls = {
  showNewControlModal: false,
  showEditControlModal: false,
  showDeleteControlModal: false,
  showUpdateControlModeModal: false,
  timeSeries: {
    lightSensorReadings: {
      byControlBoardId: {},
      bySchedulerId: {},
    },
  },
  historicDataByControlBoardId: {
    lightReadings: {},
  },
  actuatorCircuitActivityById: {},
  localOverrideActivityByControlBoardId: {},
  systemOverrideByControlId: {},
};

const normalizeSchedulerApiReturn = (data: any): LightReading[] => {
  return zipTSApiData(data).map((d: ZippedTimeSeriesApiRes) => {
    const {
      time,
      threshold,
      actionInterval,
      hysteresis,
      lightSensorSourceId,
      lightSensorTimetableId,
      schedulerTimetableId,
    } = d;

    const normalizedLightReading: LightReading = {
      timestamp: getUnixTimestamp(time.toString()) * 1000,
      threshold: threshold || null,
    };

    return normalizedLightReading;
  });
};

const normalizeLightReadingApiReturn = (data: any, lightSensorPort: LightSensorPort): LightReading[] => {
  return zipTSApiData(data).map((d: ZippedTimeSeriesApiRes) => {
    const controlsData: LightReading = d.controlsData
      ? JSON.parse(d.controlsData)
      : null;

    const lux = lightSensorPort === LightSensorPort.PORT_1 ? d.port1Lux : d.port2Lux;

    const normalizedLightReading: LightReading = {
      timestamp: getUnixTimestamp(d.time.toString()) * 1000,
      // 10/22/2021 - We still want to keep this threshold value because not all
      // edge devices will be updated initially, so this value can still act as
      // a fallback. It will be overwritten by mergeTimeSeriesData if
      // Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS has threshold data
      lux: lux,
      threshold: controlsData?.[0]?.threshold || null,

      //Below values are available, but we don't use
      // hysteresis: controlsData?.[0]?.hysteresis || null
      // schedulerId: controlsData?.[0]?.schedulerId || null,
      // timetableId: controlsData?.[0]?.timetableId || null
    };

    return normalizedLightReading;
  });
};

const normalizeActuatorOrLocalOverrideActivityApiReturn = (
  data: ActuatorActivityApiReturn | LocalOverrideActivityApiReturn
) => {
  return zipTSApiData(data).map(
    (
      d: ZippedActuatorCircuitActivityData | ZippedLocalOverrideActivityData
    ) => ({
      timestamp: parseISO(d.time).getTime(),
      state: d.state,
    })
  );
};

const normalizeSystemOverrideActivityApiReturn = (
  data: SystemOverrideActivityApiReturn
): SystemOverrideActivity[] => {
  const systemOverrideColumnData: SystemOverrideApiRes[] = zipTSApiData(data);
  const dataWithTimestamp: SystemOverrideActivity[] = systemOverrideColumnData.map(
    data => ({
      timestamp: parseISO(data.time).getTime(),
      workingMode: WorkingModeMap[data.workingMode],
    })
  );
  return dataWithTimestamp;
};

const controls = (state: Controls = initialState, action: any) => {
  switch (action.type) {
    case Actions.TOGGLE_NEW_CONTROL_MODAL:
      return pipe(assocPath(['showNewControlModal'], action.value))(state);

    case Actions.TOGGLE_EDIT_CONTROL_MODAL:
      return pipe(
        assocPath(['showEditControlModal'], action.value),
        assocPath(['typeOfControl'], action.value ? action.payload : undefined)
      )(state);

    case Actions.TOGGLE_DELETE_CONTROL_MODAL:
      return pipe(assocPath(['showDeleteControlModal'], action.value))(state);

    case Actions.TOGGLE_UPDATE_CONTROL_MODE_MODAL:
      return assocPath(['showUpdateControlModeModal'], action.value, state);

    case Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_SUCCESS: {
      const lightReadingTimeSeries = normalizeLightReadingApiReturn(
        action.data,
        action.lightSensorPort
      );
      const { min, max } = getTimeSeriesMinMax(lightReadingTimeSeries, 'lux');

      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'byControlBoardId',
            action.controlBoardId,
          ],
          {
            isLoading: false,
            data: lightReadingTimeSeries,
            min,
            max,
          }
        )
      )(state);
    }

    case Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_LOADING:
      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'byControlBoardId',
            action.controlBoardId,
          ],
          {
            isLoading: true,
          }
        )
      )(state);

    case Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_ERROR:
      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'byControlBoardId',
            action.controlBoardId,
          ],
          {
            isLoading: false,
          }
        )
      )(state);

    case Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS:
      const lightThresholdTimeSeriesFromSchedule = normalizeSchedulerApiReturn(
        action.data
      );
      const { min: minThreshold, max: maxThreshold } = getTimeSeriesMinMax(
        lightThresholdTimeSeriesFromSchedule,
        'threshold'
      );

      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'bySchedulerId',
            action.schedulerId,
          ],
          {
            isLoading: false,
            data: lightThresholdTimeSeriesFromSchedule,
            min: minThreshold,
            max: maxThreshold,
          }
        )
      )(state);

    case Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_LOADING:
      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'bySchedulerId',
            action.schedulerId,
          ],
          {
            isLoading: true,
          }
        )
      )(state);

    case Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_ERROR:
      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'bySchedulerId',
            action.schedulerId,
          ],
          {
            isLoading: false,
          }
        )
      )(state);

    case Actions.GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_SUCCESS: {
      const astroClockArray: SiteAstronomicalClock[] = action.astroClockArray;
      const luxData = normalizeLightReadingApiReturn(action.data, action.lightSensorPort);
      const flatAstroArrayWithLux = calcFlatAstroArrayWithLux(
        astroClockArray,
        luxData,
        action.timezone
      );
      const luxValuesByAstroEvent = calculateAstroEventLuxAveragesFromFlatArray(
        astroClockArray,
        flatAstroArrayWithLux
      );

      return pipe(
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'yesterday',
          ],
          luxValuesByAstroEvent
        ),
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'yesterdayIsLoading',
          ],
          false
        )
      )(state);
    }

    case Actions.GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_LOADING: {
      const astroClockArray: SiteAstronomicalClock[] = action.astroClockArray;
      const midIdx = Math.floor(astroClockArray.length / 2);
      return pipe(
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'yesterday',
          ],
          {
            dawn: {
              lux: null,
              time: astroClockArray[midIdx].dawn,
            },
            sunrise: {
              lux: null,
              time: astroClockArray[midIdx].sunrise,
            },
            sunset: {
              lux: null,
              time: astroClockArray[midIdx].sunset,
            },
            dusk: {
              lux: null,
              time: astroClockArray[midIdx].dusk,
            },
          }
        ),
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'yesterdayIsLoading',
          ],
          true
        )
      )(state);
    }

    case Actions.GET_LIGHT_READINGS_YESTERDAY_HISTORIC_DATA_ERROR:
      return pipe(
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'yesterdayIsLoading',
          ],
          false
        )
      )(state);

    case Actions.GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_SUCCESS: {
      const astroClockArray: SiteAstronomicalClock[] = action.astroClockArray;
      const luxData = normalizeLightReadingApiReturn(action.data, action.lightSensorPort);
      const flatAstroArrayWithLux = calcFlatAstroArrayWithLux(
        astroClockArray,
        luxData,
        action.timezone
      );
      const luxValuesByAstroEvent = calculateAstroEventLuxAveragesFromFlatArray(
        astroClockArray,
        flatAstroArrayWithLux
      );

      return pipe(
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'avg7Days',
          ],
          luxValuesByAstroEvent
        ),
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'avg7DaysIsLoading',
          ],
          false
        )
      )(state);
    }

    case Actions.GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_LOADING: {
      const astroClockArray: SiteAstronomicalClock[] = action.astroClockArray;
      const midIdx = Math.floor(astroClockArray.length / 2);
      return pipe(
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'avg7Days',
          ],
          {
            dawn: {
              lux: null,
              time: astroClockArray[midIdx].dawn,
            },
            sunrise: {
              lux: null,
              time: astroClockArray[midIdx].sunrise,
            },
            sunset: {
              lux: null,
              time: astroClockArray[midIdx].sunset,
            },
            dusk: {
              lux: null,
              time: astroClockArray[midIdx].dusk,
            },
          }
        ),
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'avg7DaysIsLoading',
          ],
          true
        )
      )(state);
    }

    case Actions.GET_LIGHT_READINGS_7_DAY_AVG_HISTORIC_DATA_ERROR:
      return pipe(
        assocPath(
          [
            'historicDataByControlBoardId',
            'lightReadings',
            action.controlBoardId,
            'avg7DaysIsLoading',
          ],
          false
        )
      )(state);

    case Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_SUCCESS:
      return pipe(
        assocPath(['actuatorCircuitActivityById', action.actuatorId], {
          isLoading: false,
          data: normalizeActuatorOrLocalOverrideActivityApiReturn(action.data),
        })
      )(state);

    case Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_LOADING:
      return pipe(
        assocPath(
          ['actuatorCircuitActivityById', action.actuatorId, 'isLoading'],
          true
        )
      )(state);

    case Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_ERROR:
      return pipe(
        assocPath(
          ['actuatorCircuitActivityById', action.actuatorId, 'isLoading'],
          false
        )
      )(state);

    case Actions.GET_LOCAL_OVERRIDE_ACTIVITY_SUCCESS:
      return pipe(
        assocPath(
          [
            'localOverrideActivityByControlBoardId',
            action.controlBoardId,
            action.timeRange,
          ],
          {
            isLoading: false,
            data: normalizeActuatorOrLocalOverrideActivityApiReturn(
              action.data
            ),
          }
        )
      )(state);

    case Actions.GET_LOCAL_OVERRIDE_ACTIVITY_LOADING:
      return pipe(
        assocPath(
          [
            'localOverrideActivityByControlBoardId',
            action.controlBoardId,
            action.timeRange,
            'isLoading',
          ],
          true
        )
      )(state);

    case Actions.GET_LOCAL_OVERRIDE_ACTIVITY_ERROR:
      return pipe(
        assocPath(
          [
            'localOverrideActivityByControlBoardId',
            action.controlBoardId,
            action.timeRange,
            'isLoading',
          ],
          false
        )
      )(state);

    case Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_SUCCESS:
      return pipe(
        assocPath(['systemOverrideByControlId', action.controlId], {
          isLoading: false,
          data: normalizeSystemOverrideActivityApiReturn(action.data),
        })
      )(state);

    case Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_LOADING:
      return pipe(
        assocPath(
          ['systemOverrideByControlId', action.controlId, 'isLoading'],
          true
        )
      )(state);

    case Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_ERROR:
      return pipe(
        assocPath(
          ['systemOverrideByControlId', action.controlId, 'isLoading'],
          false
        )
      )(state);

    default:
      return state;
  }
};

export default controls;
