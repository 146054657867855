import {
  Actuator,
  ActuatorPortType,
  ControlBoard,
  LightSensorPort,
  ResourceType,
  GenericErrors,
  ObjectById,
} from '@energybox/react-ui-library/dist/types';
import { mapValues, isDefined } from '@energybox/react-ui-library/dist/utils';
import { getMapFromArrayOneToOne } from '@energybox/react-ui-library/dist/utils/util';
import * as R from 'ramda';

import { Actions } from '../actions/control_boards';
import { Actions as StreamActions } from '../actions/streamApi';

import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import {
  checkIfReadingIsMoreRecent,
  normalizeSubscribedSensorReading,
} from '../utils/devices';
import { formValidationErrors } from '../utils/formValidation';
import { SensorReading } from './sensors';
import { spacesFromApiResponse } from './spaces';
import { SiteController } from '@energybox/react-ui-library/dist/types/Device';

export interface ControlBoards {
  query: string;
  actuatorsLoading: boolean;
  controlBoardIdsByParentId: ControlBoardByParentId;
  controlBoardsById: ControlBoardsById;
  controlBoardsBySiteId: ControlBoardsBySiteId;
  actuatorsByEquipmentId: ActuatorsByEquipmentId;
  actuatorsBySiteId: ObjectById<Actuator[]>;
  showNewActuatorModal: boolean;
  showDeleteActuatorModal: boolean;
  showEditActuatorModal: boolean;
  primedForDelete: {
    controlBoardId: number;
    equipmentId: number;
    port: number;
  };
  editById: EditById;
  editControlBoardById: EditControlBoardById;
  lightLiveReadingByControlBoardId: {
    [controlBoardId: string]: SensorReading;
  };
  testResults: TestResults;
}

export interface EditableFields {
  title: string;
  description: string;
  controlBoardId: number;
  equipmentId: number;
  port: number;
  portType: ActuatorPortType;
}

export interface TestResults {
  createdAt: null;
  createdBy: null;
  editedBy: null;
  id: number;
  results: {
    [equipmnetId: number]: string;
  };
  siteId: number;
  _entity: string;
}

export interface EditableControlBoardFields {
  title: string;
  description: string;
  uuid: string;
  spaceId: number;
  networkGroupId: number;
  lightSensorPort: LightSensorPort;
  model: SiteController;
}

const newControlBoardFields = {
  title: '',
  description: '',
  networkGroupId: -1,
  spaceId: -1,
  uuid: '',
  lightSensorPort: LightSensorPort.NONE,
  model: SiteController.ENERGYBOX_CB,
};

const editableControlBoardFields = (controlBoard: object) =>
  R.pick(
    [
      'spaceId',
      'networkGroupId',
      'title',
      'description',
      'uuid',
      'lightSensorPort',
      'model',
    ],
    controlBoard
  );

export type EditActuator = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

export type EditControlBoard = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableControlBoardFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const newActuatorFields = {
  title: '',
  description: '',
  equipmentId: -1,
  port: -1,
  controlBoardId: -1,
  lightSensorPort: LightSensorPort.NONE,
  portType: ActuatorPortType.NORMALLY_CLOSED,
};

const newActuator = {
  isLoading: false,
  isChanged: false,
  fields: newActuatorFields,
  formErrors: formValidationErrors('actuator', newActuatorFields),
  formErrorsVisible: false,
  apiError: {},
};

export const newControlBoard = {
  isLoading: false,
  isChanged: false,
  fields: { ...newControlBoardFields },
  formErrors: formValidationErrors('controlBoard', {
    ...newControlBoardFields,
  }),
  formErrorsVisible: false,
  apiError: {},
};

export type EditById = {
  [id: string]: EditActuator;
};

export type EditControlBoardById = {
  [id: string]: EditControlBoard;
};

export type ControlBoardsById = {
  [id: string]: ControlBoard;
};
export type ControlBoardsBySiteId = {
  [id: string]: ControlBoard;
};

export type ControlBoardByParentId = {
  [id: string]: number[];
};

export type ActuatorsByEquipmentId = {
  [id: string]: Actuator[];
};

export const ControlBoardsFromApiResponse = (data: any) => ({
  id: data.id,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  title: data.title,
  description: data.description || '',
  networkGroup: data.networkGroup,
  networkGroupId: data.networkGroupId,
  actuators: data.actuators,
  actuatorsStates: data.actuatorsStates,

  gatewayModel: data.gatewayModel || undefined,
  modelId: data.modelId || undefined,
  spaceId: data.spaceId,
  pairedSensorIds: data.pairedSensorIds || undefined,
  pairedSensors: data.pairedSensors,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],

  scannedSensorIds: data.scannedSensorIds || undefined,
  scannedSensors: data.scannedSensors,

  sensorsWhitelist: data.sensorsWhitelist || undefined,

  uuid: data.uuid,
  vendor: data.vendor,
  model: data.model,
  lightSensorPort: data.lightSensorPort || LightSensorPort.NONE,
  edgeId: data.edgeId,
  productId: data.productId || undefined,
  space: spacesFromApiResponse(data.space),
  gatewayInfo: data.gatewayInfo || undefined,
  gatewayConfig: data.gatewayConfig || undefined,
  gatewayStatus: data.gatewayStatus || undefined,
  gatewayOnlineStatus: data.gatewayOnlineStatus || undefined,

  lightSensorPublishingInterval: data.lightSensorPublishingInterval,
});

const initialState = {
  query: '',
  actuatorsLoading: false,
  controlBoardIdsByParentId: {},
  controlBoardsById: {},
  controlBoardsBySiteId: {},
  actuatorsByEquipmentId: {},
  actuatorsBySiteId: {},
  showNewActuatorModal: false,
  showDeleteActuatorModal: false,
  showEditActuatorModal: false,
  primedForDelete: {
    controlBoardId: -1,
    equipmentId: -1,
    port: -1,
  },
  editById: {},
  editControlBoardById: {
    ['new']: { ...newControlBoard },
  },
  lightLiveReadingByControlBoardId: {},
  startInstallerTest: {},
  endInstallerTest: {},
  switchRelays: {},
  testResults: {
    createdAt: null,
    createdBy: null,
    editedBy: null,
    id: 0,
    results: {},
    siteId: 0,
    _entity: '',
  },
};

const controlBoards = (state: ControlBoards = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_ACTUATORS_BY_EQUIPMENT_LOADING:
    case Actions.GET_ACTUATORS_BY_SITE_ID_LOADING:
      return R.pipe(R.assoc('actuatorsLoading', true))(state);

    case Actions.GET_CONTROL_BOARD_LOADING:
      return R.pipe(
        R.assocPath(['editControlBoardById', action.id, 'isLoading'], true)
      )(state);
    case Actions.GET_CONTROL_BOARD_ERROR:
      return R.pipe(
        R.assocPath(['editControlBoardById', action.id, 'isLoading'], false)
      )(state);
    case Actions.GET_CONTROL_BOARD_SUCCESS:
      return R.pipe(
        R.assocPath(['editControlBoardById', action.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableControlBoardFields(
            ControlBoardsFromApiResponse(action.data)
          ),
          formErrors: {},
          apiError: {},
        }),
        R.assocPath(
          ['controlBoardsById', action.id],
          ControlBoardsFromApiResponse(action.data)
        )
      )(state);

    case Actions.CREATE_CONTROL_BOARD_LOADING:
      return R.assocPath(
        ['editControlBoardById', action.id, 'isLoading'],
        true,
        state
      );
    case Actions.CREATE_CONTROL_BOARD_ERROR:
      return R.pipe(
        R.assocPath(
          ['editControlBoardById', 'new', 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editControlBoardById', 'new', 'isLoading'], false)
      )(state);
    case Actions.CREATE_CONTROL_BOARD_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['controlBoardsById', action.data.id],
          ControlBoardsFromApiResponse(action.data)
        ),
        R.assocPath(['editControlBoardById', 'new'], { ...newControlBoard }),
        R.assocPath(['editControlBoardById', action.id, 'apiError'], {})
      )(state);

    case Actions.CLEAR_FORM_ERRORS:
      return R.pipe(
        R.assocPath(['editControlBoardById', 'new', 'apiError'], {}),
        R.assocPath(['editControlBoardById', 'new'], { ...newControlBoard })
      )(state);

    case Actions.DELETE_CONTROL_BOARD_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);
    case Actions.DELETE_CONTROL_BOARD_ERROR:
      return R.assocPath(['editById', action.id, 'isLoading'], false, state);
    case Actions.DELETE_CONTROL_BOARD_SUCCESS:
      return R.pipe(
        R.dissocPath(['controlBoardsById', action.id]),
        R.dissocPath(['editControlBoardById', action.id])
      )(state);

    case Actions.PATCH_CONTROL_BOARD_LOADING:
      return R.assocPath(
        ['editControlBoardById', action.id, 'isLoading'],
        true,
        state
      );
    case Actions.PATCH_CONTROL_BOARD_ERROR:
      return R.pipe(
        R.assocPath(
          ['editControlBoardById', action.id, 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editControlBoardById', action.id, 'isLoading'], false)
      )(state);
    case Actions.PATCH_CONTROL_BOARD_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['controlBoardsById', action.id],
          ControlBoardsFromApiResponse(action.data)
        ),
        R.assocPath(['editControlBoardById', action.id, 'isChanged'], false),
        R.assocPath(['editControlBoardById', action.id, 'isLoading'], false),
        R.assocPath(['editControlBoardById', action.id, 'apiError'], {})
      )(state);

    case Actions.GET_CONTROL_BOARDS_LOADING:
      return R.pipe(R.assocPath(['editById', 'new', 'isLoading'], true))(state);
    case Actions.GET_CONTROL_BOARDS_SUCCESS:
      return action.data.length > 0
        ? R.pipe(
            R.assocPath(['editById', 'new', 'isLoading'], false),
            R.assoc(
              'controlBoardIdsByParentId',
              R.mergeRight(
                R.view(R.lensProp('controlBoardIdsByParentId'), state),
                R.reduceBy(
                  (acc, { id }) => acc.concat(id),
                  [],
                  ({ spaceId }) => spaceId,
                  action.data
                )
              )
            ),
            R.assoc(
              'controlBoardsById',
              R.mergeRight(
                R.view(R.lensProp('controlBoardsById'), state),
                getMapFromArrayOneToOne(
                  mapValues(action.data, ControlBoardsFromApiResponse)
                )
              )
            )
          )(state)
        : R.pipe(
            R.assoc('controlBoardIdsByParentId', {}),
            R.assoc('controlBoardsById', {})
          )(state);

    case Actions.GET_CONTROL_BOARDS_BY_SITEID_SUCCESS:
      return R.pipe(
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc(
          'controlBoardsBySiteId',
          R.mergeRight(
            R.view(R.lensProp('controlBoardsBySiteId'), state),
            getMapFromArrayOneToOne(
              mapValues(action.data, ControlBoardsFromApiResponse)
            )
          )
        )
      )(state);

    case Actions.GET_ACTUATORS_BY_EQUIPMENT_SUCCESS:
      return R.pipe(
        R.assoc('actuatorsLoading', false),
        R.assocPath(['actuatorsByEquipmentId', action.equipmentId], action.data)
      )(state);

    case Actions.GET_ACTUATORS_BY_SITE_ID_SUCCESS:
      return R.pipe(
        R.assoc('actuatorsLoading', false),
        R.assocPath(['actuatorsBySiteId', action.siteId], action.data)
      )(state);

    case Actions.TOGGLE_NEW_ACTUATOR_MODAL: {
      return R.pipe(
        R.assocPath(['editById', 'new'], newActuator),
        R.assocPath(
          ['editById', 'new', 'fields', 'equipmentId'],
          action.equipmentId
        ),
        R.assoc('showNewActuatorModal', action.value)
      )(state);
    }

    case Actions.SHOW_EDIT_ACTUATOR_MODAL:
      return R.pipe(
        R.assocPath(['editById', action.actuatorId], {
          isLoading: false,
          isChanged: false,
          fields: R.find(
            R.propEq('id', action.actuatorId),
            state.actuatorsByEquipmentId[action.equipmentId]
          ),
          formErrors: {},
          formErrorsVisible: false,
          apiError: {},
        }),
        R.assoc('showEditActuatorModal', action.value)
      )(state);

    case Actions.HIDE_EDIT_ACTUATOR_MODAL:
      return R.assoc('showEditActuatorModal', action.value, state);

    case Actions.TOGGLE_DELETE_ACTUATOR_MODAL:
      return R.assoc('showDeleteActuatorModal', action.value, state);

    case Actions.PATCH_ACTUATOR_SUCCESS:
      return R.pipe(
        R.assocPath(['controlBoardsById', action.data.id], action.data),
        R.assoc('showEditActuatorModal', false)
      )(state);

    case Actions.DELETE_ACTUATOR_SUCCESS:
      return R.pipe(
        R.dissocPath(['editById', action.actuatorId]),
        R.assoc('showDeleteActuatorModal', false)
      )(state);

    case Actions.DELETE_ACTUATOR_LOADING:
      return R.assocPath(
        ['editById', action.actuatorId, 'isLoading'],
        true,
        state
      );

    case Actions.CREATE_ACTUATOR_ERROR: {
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);
    }

    case Actions.DELETE_ACTUATOR_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', action.actuatorId, 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', action.actuatorId, 'isLoading'], false)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.pipe(
        R.assocPath(['editById', action.id, 'formErrorsVisible'], action.value)
      )(state);

    case Actions.DISPLAY_CONTROL_BOARD_FORM_ERRORS:
      return R.assocPath(
        ['editControlBoardById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD: {
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('actuator', updatedField)
        )
      )(state);
    }

    case Actions.UPDATE_CONTROL_BOARD_FIELD: {
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editControlBoardById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(
          ['editControlBoardById', action.id, 'fields'],
          updatedField
        ),
        R.assocPath(['editControlBoardById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editControlBoardById', action.id, 'formErrors'],
          formValidationErrors('controlBoard', updatedField)
        )
      )(state);
    }

    case Actions.CREATE_ACTUATOR_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['controlBoardsById', action.data.id],
          ControlBoardsFromApiResponse(action.data)
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewActuatorModal', false)
      )(state);

    case Actions.CREATE_ACTUATOR_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case StreamActions.RECEIVED_DEVICE_READING:
      const controlBoard = state.controlBoardsById[action.data.sensorId];
      if (!controlBoard) return state;

      const normalizedPayload: SensorReading = normalizeSubscribedSensorReading(
        action.data,
        controlBoard.lightSensorPort
      );
      const { sensorId: controlBoardId, lux } = normalizedPayload;

      if (!isDefined(lux)) return state;

      let oldValue: SensorReading | undefined = undefined;

      oldValue = R.pathOr(
        null,
        ['lightLiveReadingByControlBoardId', controlBoardId],
        state
      );

      if (!checkIfReadingIsMoreRecent(action.data, oldValue)) {
        return state;
      } else {
        return R.assocPath(
          ['lightLiveReadingByControlBoardId', controlBoardId],
          normalizedPayload,
          state
        );
      }

    case Actions.POST_START_INSTALLER_TEST_LOADING:
      return R.assocPath(
        ['startInstallerTest', action.id, 'isLoading'],
        true,
        state
      );
    case Actions.POST_START_INSTALLER_TEST_SUCCESS:
      return R.pipe(
        R.assocPath(['startInstallerTest', action.id], action.data),
        R.assocPath(['startInstallerTest', action.id, 'isLoading'], false)
      )(state);
    case Actions.POST_START_INSTALLER_TEST_ERROR:
      return R.pipe(
        R.assocPath(['startInstallerTest', action.id, 'isLoading'], false)
      )(state);

    case Actions.POST_END_INSTALLER_TEST_LOADING:
      return R.assocPath(
        ['endInstallerTest', action.id, 'isLoading'],
        true,
        state
      );
    case Actions.POST_END_INSTALLER_TEST_SUCCESS:
      return R.pipe(
        R.assocPath(['endInstallerTest', action.id], action.data),
        R.assocPath(['endInstallerTest', action.id, 'isLoading'], false)
      )(state);
    case Actions.POST_END_INSTALLER_TEST_ERROR:
      return R.pipe(
        R.assocPath(['endInstallerTest', action.id, 'isLoading'], false)
      )(state);

    case Actions.POST_SWITCH_RELAYS_TEST_LOADING:
      return R.assocPath(['switchRelays', action.id, 'isLoading'], true, state);
    case Actions.POST_SWITCH_RELAYS_TEST_SUCCESS:
      return R.pipe(
        R.assocPath(['switchRelays', action.id], action.data),
        R.assocPath(['switchRelays', action.id, 'isLoading'], false)
      )(state);
    case Actions.POST_SWITCH_RELAYS_TEST_ERROR:
      return R.pipe(
        R.assocPath(['switchRelays', action.id, 'isLoading'], false)
      )(state);

    case Actions.GET_TEST_RESULTS_LOADING:
    case Actions.POST_TEST_RESULTS_LOADING:
      return R.assocPath(['testResults', 'isLoading'], true, state);

    case Actions.GET_TEST_RESULTS_SUCCESS:
    case Actions.POST_TEST_RESULTS_SUCCESS:
      return R.pipe(
        R.assocPath(['testResults'], action.data),
        R.assocPath(['testResults', 'isLoading'], false)
      )(state);

    case Actions.GET_TEST_RESULTS_ERROR:
    case Actions.POST_TEST_RESULTS_ERROR:
      return R.pipe(R.assocPath(['testResults', 'isLoading'], false))(state);

    default:
      return state;
  }
};

export default controlBoards;
